@import "./bootstrap-overrides_v1.scss";

.questionnaire {
  color: $medium-grey;
  .accordion-wrapper {
    text-align: left;

    & + * {
      margin-top: 0.5em;
    }

    .accordion-item {
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
      height: auto;
      max-height: 9999px;
      color: $medium-grey;
    }

    .accordion-item.collapsed {
      max-height: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }

    .accordion-title {
      font-weight: 600;
      cursor: pointer;
      color: $medium-grey;
      padding: 0.5em 1em;
      border: solid 1px #ccc;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
      }

      &:hover,
      &.open {
        color: $black;
      }

      &.open {
        &::after {
          content: "";
          border-top: 0;
          border-bottom: 5px solid;
        }
      }
    }

    .accordion-content {
      padding: 1em 1.5em;
    }

    .accordionLabel {
      padding: 0;
      input {
        margin-right: 10px;
      }
    }
    .accordionBody {
      margin-bottom: 20px;
    }
  }

  .card {
    min-height: unset;
  }
  .carousel-control-next,
  .carousel-control-prev {
    color: $black !important;
  }

  .slidecontainer {
    width: 100%;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;
    background-color: $light-grey-slider;
    outline: none;
    opacity: 0.7;
    @include transition(all, 0.2s, ease-in-out);

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $medium-green;
      cursor: pointer;
      position: relative;
      bottom: 2px;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $medium-green;
      cursor: pointer;
      position: relative;
      bottom: 2px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    filter: invert(100%);
    height: 75px;
  }

  .demographics {
    label {
      input {
        margin-top: 10px;
      }
    }
  }
}
