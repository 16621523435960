@import "../../css/variables.scss";

.navbar {
  padding: 0 0.5rem !important;
}
.navbar-bg {
  background-color: $medium-green;
}
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: $white !important;
    }
  }
}
.navbar-nav {
  margin-left: auto;
  .dropdown-menu {
    position: absolute !important;
  }
}
.user-picture {
  height: 40px;
  border-radius: 50%;
  padding: 0;
}

.lng-container {
  position: absolute;
  right: 0;
  margin-right: 16px;
}

.btn-lng {
  width: 24px;
  height: 20px;
  padding: 0px;
  border: none;
  font-size: 12px;
  color: $grey-label-text;
  transition: background-color 0.2s;
  &:hover {
    background-color: $light-grey-background;
    color: $medium-green;
    font-weight: bold;
  }
  & + & {
    border-radius: 0px 2px 2px 0px;
  }
}

.btn-lng:first-child {
  border-radius: 2px 0px 0px 2px;
}

.btn-active {
  font-weight: bold;
  color: $bright-orange;
  background-color: $orange;
  &:hover {
    background-color: $light-orange-second;
    color: $bright-orange;
  }
}