@import "bootstrap";
@import "variables.scss";

button {
  &:focus {
    outline: none;
  }
}

.btn-link {
  color: $medium-green;
  font-weight: $bold;
  &:hover {
    text-decoration: none;
    color: $bright-green;
  }
}

.btn-primary.ci-btn-orange:not(:disabled):not(.disabled).active,
.btn-primary.ci-btn-orange:not(:disabled):not(.disabled):active,
.show > .btn-primary.ci-btn-orange.dropdown-toggle {
  background-color: $orange;
  border-color: $orange;
}

.btn.btn-primary.ci-btn-orange.active.focus,
.btn.btn-primary.ci-btn-orange.active:focus,
.btn.btn-primary.ci-btn-orange.active:hover,
.btn.btn-primary.ci-btn-orange:active.focus,
.btn.btn-primary.ci-btn-orange:active:focus,
.btn.btn-primary.ci-btn-orange:active:hover {
  background-color: $light-orange;
  border-color: $light-orange;
}

.btn-primary.ci-btn-orange:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem $light-orange;
}

.btn-primary.ci-btn-yellow:not(:disabled):not(.disabled).active,
.btn-primary.ci-btn-yellow:not(:disabled):not(.disabled):active,
.show > .btn-primary.ci-btn-yellow.dropdown-toggle,
.btn.btn-primary.ci-btn-yellow.active.focus,
.btn.btn-primary.ci-btn-yellow.active:focus,
.btn.btn-primary.ci-btn-yellow.active:hover,
.btn.btn-primary.ci-btn-yellow:active.focus,
.btn.btn-primary.ci-btn-yellow:active:focus,
.btn.btn-primary.ci-btn-yellow:active:hover {
  background-color: $yellow;
  border-color: $yellow;
}

.btn-primary.ci-btn-yellow:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem $yellow;
}

.btn-light {
  background-color: $orange;
  border-color: $orange;
  color: $white;
  &:hover {
    background-color: $light-orange;
    border-color: $light-orange;
    color: $white;
  }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: $medium-green;
  border-color: $medium-green;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $medium-green;
  border-color: $medium-green;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary {
  &.ci-btn-orange {
    background-color: $orange !important;
    border-color: $orange !important;
    width: 100%;
    padding: 12px 10px 12px 10px;
    border-collapse: separate !important;
    border-radius: 70px;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 100%;
    text-align: center;
    text-decoration: none;
    color: $white;
    display: block;
    margin-top: 3em;
    box-shadow: 0px 5px 8px $light-grey-box-shadow-button;

    &:hover {
      color: $white;
      background-color: $light-orange !important;
      border-color: $light-orange !important;
    }

    &:active {
      color: $white;
      background-color: $light-orange;
      border-color: $light-orange;
    }
  }

  &.ci-btn-yellow {
    background-color: $yellow;
    border-color: $yellow;
    margin: auto;
    width: 100%;
    padding: 12px 10px 12px 10px;
    border-collapse: separate !important;
    border-radius: 70px;
    background-color: $yellow;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 100%;
    text-align: center;
    text-decoration: none;
    color: $white;
    display: block;
    margin-top: auto;
    margin-bottom: initial;
    box-shadow: 0px 5px 8px $light-grey-box-shadow-button;

    &:hover {
      color: $white;
      background-color: $yellow;
      border-color: $yellow;
    }

    &:active {
      color: $white;
      background-color: $yellow;
      border-color: $yellow;
    }
  }

  &.btn-success {
    background-color: $dark-green;
    border-color: $dark-green;
    font-size: 14px;
    border-radius: 50%;
    padding: 0;
    width: 25px;
    height: 25px;
    top: 5px;
    position: relative;

    &:hover {
      color: $white;
      background-color: $bright-green;
      border-color: $bright-green;
    }

    &:active {
      color: $white;
      background-color: $bright-green;
      border-color: $bright-green;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $bright-green;
    }
  }
}

.btn-secondary {
  background-color: $orange !important;
  border-color: $orange !important;

  &:hover {
    color: $white;
    background-color: $light-orange !important;
    border-color: $light-orange !important;
  }

  &.ci-btn-help {
    background-color: $orange;
    border-color: $orange;
    font-size: 8px;
    border-radius: 50%;
    padding: 0;
    width: 15px;
    height: 15px;
    top: 5px;
    position: relative;

    &:hover {
      color: $white;
      background-color: $light-orange;
      border-color: $light-orange;
    }

    &:active {
      color: $white;
      background-color: $light-orange;
      border-color: $light-orange;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $light-orange;
    }
  }
}

.card-header {
  padding-top: 20px;
  color: $medium-grey;
  font-family: Helvetica;
  font-size: 1.4em;
  background-color: $white;
  box-shadow: 0px 2px 0px $light-grey-box-shadow-card;
  border-color: $light-grey-box-shadow-card;
  z-index: 1;
  span {
    font-size: 0.65em;
    color: $light-medium-grey;
    display: block;
  }
}

.card-title,
.card-text {
  padding: 10px;
  color: $medium-grey;
}

.card-text {
  &.quote {
    color: #888;
    font-size: 0.95em;
  }
}

.list-text {
  color: $medium-grey;
}

.card-body {
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.card {
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
}

.card-dashboard {
  min-height: 0;
}

.carousel-indicators {
  & > li {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: $dark-green;
  }
}

.carousel-item{
  scroll-behavior: smooth;
}

.popover {
  background-color: $orange;

  &.bs-popover-top {
    .arrow {
      &::after {
        border-top-color: $orange;
      }
    }
  }

  &.bs-popover-bottom {
    .arrow {
      &::after {
        border-bottom-color: $orange;
      }
    }
  }
}

.popover-content {
  background-color: $orange;
}

.popover-body {
  color: $white;
}

.row {
  flex-grow: 1;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
  background-color: $medium-green;
  color: $white !important;
}

small {
  &.mutted-text {
    color: $medium-grey;
  }
}

.pagination {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  display: inline-flex;
}

.page-item.active .page-link {
  background-color: $orange;
  border-color: $orange;
}

.page-link {
  color: $medium-grey;
  padding-left: 13px;
  padding-right: 13px;

  &:hover {
    color: $orange;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 135, 104, 0.25);
  }
}

.modal-header {
  border-color: $light-grey-box-shadow-card;
  box-shadow: 0px 1px 0px $light-grey-box-shadow-card;
}

.table {
  thead {
    th{
      font-size: 0.9em;
      color: $medium-grey;
      width: 100%;
      vertical-align: inherit;
    }
  }
  tbody{
    tr{
      td{
        label{
          vertical-align: inherit;
          margin-bottom: unset;
          text-align:center;
          padding: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .table {
    thead {
      th{
        width: 100%;
      }
    } 
  }
}
  